
import Icon from '@/components/Icon.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCCList',
  components: { PageWithHeader, Icon },
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: String,
  },
  setup (props, ctx) {
    return {
      select (code: string) {
        ctx.emit('select', code)
        ctx.emit('close')
      },
    }
  },
})
