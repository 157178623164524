
import Input from 'common/form/inputs/FormInput.vue'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'Code',
  components: { Input },
  emits: ['timeout'],
  props: {
    duration: {
      type: Number,
      default: 60,
    },
  },
  setup (props, ctx) {
    const inCD = shallowRef(false)
    const left = shallowRef(0)
    let timer = 0

    const timeout = () => {
      clearInterval(timer)
      inCD.value = false
      ctx.emit('timeout')
    }

    const cd = () => {
      if (inCD.value) return
      left.value = props.duration
      inCD.value = true

      timer = window.setInterval(() => {
        if (left.value <= 1) {
          timeout()
        }
        left.value -= 1
      }, 1000)
    }

    onBeforeUnmount(() => {
      clearInterval(timer)
    })

    return {
      left,
      inCD,
      cd,
    }
  }
})
