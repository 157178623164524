import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")

  return (_openBlock(), _createBlock(_component_Input, null, {
    addon: _withCtx(() => [
      (_ctx.inCD)
        ? _renderSlot(_ctx.$slots, "text", {
            key: 0,
            left: _ctx.left
          }, () => [
            _createVNode("span", null, _toDisplayString(_ctx.left) + "S", 1)
          ])
        : _renderSlot(_ctx.$slots, "addon", {
            key: 1,
            cd: _ctx.cd
          })
    ]),
    _: 1
  }))
}