
import Code from 'common/form/inputs/Code.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCodeInput',
  components: { Code },
  emits: ['send'],
  setup (props, ctx) {
    return {
      send (cd: () => void) {
        ctx.emit('send', cd)
      },
    }
  },
})
